<template>
  <b-card
    no-body
    class="mb-0"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2 col-md-6 col-xs-12"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <h5>
          {{ title }}
        </h5>

        <!-- Nom -->
        <validation-provider
          #default="validationContext"
          name="Nom"
          rules="required"
        >
          <b-form-group
            label="Nom de la marque"
            label-for="nom"
          >
            <b-form-input
              id="nom"
              v-model="marqueData.nom"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              Le champ Nom est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            {{ submitButtonText }}
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-card>

</template>

<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    marqueFormData: {
      type: Object,
      default: () => ({
        nom: '',
      }),
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const marqueData = ref({ ...props.marqueFormData })

    const resetMarqueData = () => {
      marqueData.value = { ...props.marqueFormData }
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetMarqueData)

    const onSubmit = () => {
      emit('submitMarqueForm', marqueData.value)
    }

    return {
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      marqueData,
    }
  },
}
</script>
